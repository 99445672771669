<template>
  <div class="success-page">
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div v-if="user.company?.successCard === 'default' || !user.company" class="wrap">
        <div class="icon-place">
          <n-icon icon="success" />
          <div class="text">{{ hello }}!</div>
        </div>
        <div class="button-place">
          <n-button @click="go">Готово</n-button>
        </div>
      </div>
      <div v-if="user.company?.successCard === 'bazis'" class="wrap bazis">
        <div class="title">Доступ разрешен </div>
        <div class="photo-wrapper">
          <div class="photo-place">
            <img :src="user?.avatar" alt="">
          </div>
        </div>
        <div class="data">
          <div class="row">ФИО: {{ user.fullName }}</div>
          <div class="row">ИИН: {{ user.iin }}</div>
          <div class="row">
            Филиал:
            <span v-for="(item, index) in user.branches" :key="item.id">
              {{ item.title + (index+1 !== user.branches?.length ? ',' : '') }}
            </span>
          </div>
          <div class="row">Отдел: {{ user.department?.title }}</div>
          <div class="row">Должность: {{ user.position }}</div>
        </div>
        <div class="button-place">
          <n-button @click="go">Готово</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/logo.png'

export default {
  name: 'PageSuccess',
  data() {
    return {
      logo,
      hello: '',
      user: $app.auth.user(),
    }
  },
  created() {
    this.calcHello()
  },
  methods: {
    go() {
      $app.store.action('app.updateUser').then(() => {
        this.$router.push({ name: 'history', })
      })
    },
    calcHello() {
      const currentH = $app.date.format(new Date().getTime(), 'time').substr(0, 2)
      if ([ '06', '07', '08', '09', ].includes(currentH)) {
        this.hello = 'Доброе утро'
      }
      if ([ '10', '11', '12', '13', '14', '15', '16', '17', ].includes(currentH)) {
        this.hello = 'Добрый день'
      }
      if ([ '18', '19', '20', '21', '22', ].includes(currentH)) {
        this.hello = 'Добрый вечер'
      }
      if ([ '23', '0', '00', '01', '02', '03', '04', '05', ].includes(currentH)) {
        this.hello = 'Доброй ночи'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.success-page {
  .container {
    height: calc(100vh - 190px);
  }
  .wrap {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .icon-place {
      flex: 8;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .text {
        margin-top: 15px;
        text-align: center;
        color: #64C038;
        font-size: 1.2em;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .bazis {
    align-items: stretch;
    justify-content: normal;
    .title {
      font-size: 20px;
      font-weight: 700;
      color: #2D8D00;
      text-align: center;
      margin-bottom: 20px;
    }
    .data {
      margin-bottom: 20px;
      .row {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 10px;
        color: #444444;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .photo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .photo-place {
      width: 160px;
      height: 210px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .button-place {
    display: flex;
    align-items: end;
    justify-content: center;
    flex: 0.7;
    ::v-deep .n-button {
      width: 80vw;
      background-color: #57419D;
      border-radius: 32px;
      color: #FFFFFF;
      text-transform: uppercase;
      height: 40px;
    }
  }

}
</style>
